import { settingsStore } from "@/Lib/stores/SettingsStore";
import { useMobxValue } from "@/Features/PublicApp/Hooks";
import { deleteCache } from "@/Lib/storage";
import { LangTranslation, Languages } from "@/Lib";
import config from "@/config";
import { ILanguageCode } from "@/Lib/const";

export function useT(): LangTranslation {
  const [, t] = useAppLang();

  return t;
}

export function useAppLang() {
  const chosenLang = useMobxValue(settingsStore, "lang") as ILanguageCode;

  const toggle = (overide?: LangTranslation) => {
    if (config.languages.length !== 2) return;

    deleteCache("/auth/questionnaires");
    return settingsStore.setLang(
      overide || chosenLang === config.languages[0]
        ? config.languages[1]
        : config.languages[0]
    );
  };

  return [
    chosenLang,
    Languages[chosenLang] ?? Languages[config.default_language],
    toggle,
  ] as const;
}

export function useIsRTL() {
  const [lang] = useAppLang();
  return lang === "ar";
}

export function $t(t: any, i: string, fallback?: string) {
  if (i in t) {
    return t[i];
  }
  return fallback || i;
}
