import { positionStore } from "./PositionStore";
import { accountStore } from "./AccountStore";
import { balanceStore } from "./BalanceStore";
import { Position } from "./entities/Position";
import { ExchangeRate } from "./entities/ExchangeRate";
import { SymbolInfo } from "./entities/SymbolInfo";
import { storage } from "../storage";
import { RoundByStep, safePrintFloat } from "../helpers";
import { makeAutoObservable, reaction } from "mobx";

enum TRADETYPES {
  BUY = 0,
  SELL = 1,
  BUY_LIMIT = 2,
  SELL_LIMIT = 3,
  BUY_STOP = 4,
  SELL_STOP = 5,
}

export class OrderTicket {
  // @observer based on symbol info
  symbol: string = "";
  contractSize = 100000;
  grpLeverage = 100;
  direction: TRADETYPES = TRADETYPES.BUY;
  // conversion rates
  exchangeRate: {
    profit: ExchangeRate;
    margin: ExchangeRate;
  };
  _useUSD: boolean = storage.getBoolean("useUSD") || true;
  set useUSD(val) {
    storage.set("useUSD", !!val);
    this._useUSD = !!val;
  }
  get useUSD() {
    return this._useUSD;
  }
  toggleUSD = () => {
    this.useUSD = !this.useUSD;
  };
  limit = 0;
  exec = "NO";
  calcMode = 0;
  get min() {
    return this.Symbol.min * this.contractSize;
  }
  get max() {
    return this.Symbol.max * this.contractSize;
  }
  get Pip() {
    return this.Point * 10;
  }
  get Point() {
    return Number(
      this.Units *
        Number(this.Symbol.data.Point) *
        this.exchangeRate.profit.fromCurrencyToAccount
    );
  }
  get Swap() {
    return Number(this.Symbol.data.SwapMode) === 0
      ? "0"
      : this.isBuy
      ? (Number(this.Symbol.data.SwapLong) * this.Point).toFixed(2)
      : (Number(this.Symbol.data.SwapShort) * this.Point).toFixed(2);
  }
  currentPosition?: Position;
  Symbol: SymbolInfo;
  constructor(symbol: SymbolInfo) {
    this.Symbol = symbol;
    this.symbol = symbol.data.Symbol;
    this.currentPosition = positionStore.bySymbol[this.symbol];
    this.calcMode = Number(symbol.data.CalcMode);
    this.contractSize = Number(symbol.data.ContractSize);
    this.exchangeRate = {
      profit: new ExchangeRate(symbol, "Profit"),
      margin: new ExchangeRate(symbol, "Margin"),
    };

    makeAutoObservable(this, undefined, { deep: false });
    this.useUSD = storage.getBoolean("useUSD") || true;
    reaction(
      () => this.useUSD,
      (arg) => storage.set("useUSD", Boolean(arg))
    );
    // default to 10% of free Margin
    // waitFor(() => !!this.exchangeRate.margin.Symbol).then(() => {
    //   // this.setVolume(Math.floor(Number(accountStore.MarginFree) * .10 / 100) * 100);
    //   this.setVolume(0)
    // });
  }

  get PriceStep() {
    return Number(this.Symbol.data.Point);
  }

  get Step() {
    const v = Number(this.Symbol.data.VolumeStepExt) || 1;
    return v / 100000000;
  }

  get StepPrice() {
    const lot = this.Step;
    const unit = lot * this.contractSize;
    return Number(
      (
        unit *
        this.TicketPrice *
        this.exchangeRate.profit.fromCurrencyToAccount
      ).toFixed(2)
    );
  }

  error = "";
  setVolume(usd: number | null, units: number | null) {
    if (units && usd) {
      throw new Error("You cannot set both at same time.. pick one");
    }

    if (!units && !usd) {
      return;
    }

    if (units) {
      this.Units = units;
      return units;
    }

    if (!usd) {
      return;
    } // this will never happen

    const moneyIHave =
      usd * this.exchangeRate.margin.fromAccountToCurrency * this.Leverage;
    null;
    if (this.calcMode === 0) {
      this.Units = moneyIHave;
      return moneyIHave;
    } else {
      this.Units = moneyIHave / this.TicketPrice;
      return moneyIHave / this.TicketPrice;
    }
  }

  // @ready
  _Units = 0;
  get Units() {
    return this._Units;
  }
  set Units(units) {
    this.setUnits(units);
  }
  get Value() {
    return safePrintFloat(
      this.useUSD ? this.RequiredMargin : this.Units,
      this.Symbol.Digits
    );
  }
  private setUnits(units: number) {
    const rounded = RoundByStep(units, this.Step);
    this._Units = rounded;
    // ----------- Find Errors;

    const min = Number(this.min);
    if (rounded < min) {
      // this.setUnits(min);
      this.error = "Minimum Order is " + this.Value;
      return;
    }

    const max = Number(this.max);
    const current = this.Symbol.Position;
    const tradeMode = Number(this.Symbol.data.TradeMode);

    if (current) {
      if (this.isBuy !== current.isBuy && tradeMode < 4) {
        if (rounded > current.Units) {
          this.error =
            "Maximum Order is " +
            (this.useUSD ? current.RequiredMargin : current.Units);
          return;
        }
      }
    }

    if (rounded > max) {
      this.setUnits(max);
      this.error = "Maximum Order is " + this.Value;
      return;
    }

    const limit = Number(this.Symbol.MaxUnitsPerUser);
    if (limit) {
      const oldVolume = this.currentPosition
        ? this.currentPosition.Units *
          (this.currentPosition.isBuy && this.isBuy ? 1 : -1)
        : rounded;
      if (oldVolume + rounded > limit) {
        this.error = "You Cannot Own more than " + limit + " Units in Total.";
        return;
      }
    }

    if (!this.getHasEnoughMoney()) {
      // this.setVolume(Math.round(Number(accountStore.MarginFree) * .9))
      this.error = "Insufficient funds. ";
      return;
    }

    this.error = "";
  }
  get lots() {
    return this.Units / this.contractSize;
  }
  // @ready
  get isBuy() {
    return this.direction % 2 === 0;
  }

  // live price in profit currency
  get Tick() {
    const tick = this.isBuy ? this.Symbol?.tick?.ask : this.Symbol?.tick?.bid;
    return tick || 0;
  }

  // ticket price in profit currency
  get CounterPrice() {
    if (!this.limit) {
      return this.isBuy ? this.Symbol.tick?.bid : this.Symbol.tick?.ask;
    }
    return this.isBuy ? this.limit - this.Spread : this.limit + this.Spread;
  }
  get TicketPrice() {
    return this.limit ? this.limit : this.Tick;
  }

  get Leverage() {
    return this.Symbol.getLeverage(this.isBuy);
  }

  get Exposure() {
    if (this.calcMode === 0) {
      return this.Units * this.exchangeRate.margin.fromCurrencyToAccount;
    }
    return (
      this.Units *
      this.TicketPrice *
      this.exchangeRate.margin.fromCurrencyToAccount
    );
  }

  get RequiredMargin() {
    const units = this.Units;
    const e =
      this.calcMode === 0
        ? units * this.exchangeRate.margin.fromCurrencyToAccount
        : units *
          this.TicketPrice *
          this.exchangeRate.margin.fromCurrencyToAccount;

    const rm = (e / this.Leverage).toFixed(2);
    if (rm.indexOf(".99") > -1) {
      return Math.ceil(Number(rm));
    }

    return Number(rm);
  }

  get NewMargin() {
    return Number(accountStore.MarginFree) - this.RequiredMargin;
  }

  get EquityRatio() {
    return (
      (100 / (Number(accountStore.MarginFree) / this.RequiredMargin)).toFixed(
        1
      ) + "%"
    );
  }

  getHasEnoughMoney() {
    const current = this.Symbol.Position;
    let required = this.RequiredMargin;

    if (current && current.isBuy !== this.isBuy) {
      required = Number(required) - current.RequiredMargin;
    }

    return required <= Number(accountStore.MarginFree);
  }

  get AvaliableFunds() {
    const current = this.Symbol.Position;
    let free = Number(balanceStore.Avaliable);
    if (current && current.isBuy !== this.isBuy) {
      free += current.RequiredMargin;
    }
    return free;
  }

  get tradeType(): TRADETYPES {
    if (this.limit === 0) {
      return this.direction;
    }

    return this.direction === TRADETYPES.BUY
      ? // -- buy operation
        this.limit > this.Tick
        ? TRADETYPES.BUY_STOP
        : TRADETYPES.BUY_LIMIT
      : // --- sell operation
      this.limit < this.Tick
      ? TRADETYPES.SELL_STOP
      : TRADETYPES.SELL_LIMIT;
  }

  get ProtectionRestrictedBand() {
    const tick =
      Number(this.Symbol.data.StopsLevel || 5) * this.PriceStep + this.Spread;
    const dist = Math.abs(tick);
    return this.useUSD
      ? dist * this.exchangeRate.profit.fromCurrencyToAccount
      : dist;
  }

  get Spread() {
    const spread =
      Number(this.Symbol?.tick?.ask) - Number(this.Symbol?.tick?.bid);
    return Number((spread || 0).toFixed(Number(this.Symbol.data.Digits)));
  }

  get costOfOpening() {
    return (
      Number(this.Spread) *
      this.exchangeRate.profit.fromAccountToCurrency *
      this.Units
    );
  }
  get multiplier() {
    return this.isBuy ? 1 : -1;
  }

  // --- conversion from usd to price level
  // helper for sl/tp
  getMovementRequired(usd: number) {
    if (!usd) {
      return 0;
    }
    const perlot = usd / this.Units; // in account
    const move = perlot * this.exchangeRate.profit.fromAccountToCurrency;
    return Number(move.toFixed(Number(this.Symbol.data.Digits)));
  }

  // Stop Loss IN USD
  private _sl = 0;
  set sl(val) {
    let n = Number(val);
    if (!n || Number.isNaN(n)) {
      this._sl = 0;
      return;
    }

    if (this.useUSD) {
      n = Math.abs(n) * (this.isBuy ? -1 : 1);
      const distance = this.getMovementRequired(n);
      this._sl = Number(
        (distance + this.TicketPrice).toFixed(this.Symbol.Digits)
      );
    } else {
      this._sl = Number(n.toFixed(this.Symbol.Digits));
    }
  }
  get sl() {
    return this._sl;
  }
  get slDistance() {
    if (!this._sl) {
      return 0;
    }
    const diff = this.isBuy
      ? this._sl - this.TicketPrice
      : this.TicketPrice - this._sl;
    return Number(diff.toFixed(this.Symbol.Digits));
  }
  get Distance() {
    return this.useUSD ? this.slDistance : this.slInUSD;
  }
  get slInUSD() {
    return Number(
      (
        this.slDistance *
        this.Units *
        this.exchangeRate.profit.fromCurrencyToAccount
      ).toFixed(2)
    );
  }
  slError() {
    if (!this._sl) {
      return undefined;
    }
    if (Math.abs(this.Distance) <= this.ProtectionRestrictedBand) {
      return "value is too close to the price level";
    }
    return undefined;
  }
  tpError() {
    if (!this._tp) {
      return undefined;
    }
    if (Math.abs(this.tpDistance) <= this.ProtectionRestrictedBand) {
      return "value too close to the price level";
    }
    return undefined;
  }

  private _tp = 0;
  set tp(val) {
    let n = Number(val);
    if (Number.isNaN(n)) {
      return;
    }

    if (this.useUSD) {
      n = Math.abs(n);
      const distance = this.getMovementRequired(n);
      this._tp = Number(
        (distance + this.TicketPrice).toFixed(this.Symbol.Digits)
      );
    } else {
      this._tp = Number(n.toFixed(this.Symbol.Digits));
    }
  }
  get tp() {
    return this._tp;
  }
  get tpDistance() {
    if (!this._tp) {
      return 0;
    }
    const diff = this.isBuy
      ? this._tp - this.TicketPrice
      : this.TicketPrice - this._tp;
    return Number(diff.toFixed(this.Symbol.Digits));
  }
  get tpInUSD() {
    return Number(
      (
        this.tpDistance *
        this.Units *
        this.exchangeRate.profit.fromCurrencyToAccount
      ).toFixed(2)
    );
  }
  getDefaultTp() {
    if (this.useUSD) {
      return this.RequiredMargin;
    }
    const movement = this.getMovementRequired(this.RequiredMargin);
    return Math.ceil(
      this.TicketPrice + (this.isBuy ? movement : movement * -1)
    );
  }
  getDefaultSl() {
    if (this.useUSD) {
      return this.RequiredMargin;
    }
    const movement = this.getMovementRequired(this.RequiredMargin);
    return Math.floor(
      this.TicketPrice - (this.isBuy ? movement : movement * -1)
    );
  }
}
