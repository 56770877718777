import { brazeKey, VERSION, DIST } from "./Env";
import { init } from "./Lib/analytics/braze";
import * as Sentry from "@sentry/react";

export function SentryTag(tag: string, value: string) {
  Sentry.setTag(tag, value);
}

export function startSentry() {
  if (__DEV__) return;
  Sentry.init({
    release: `${VERSION}`,
    dist: `${DIST}`,
    dsn: "https://de08990711924d77ace04a69da95841f@o1228533.ingest.sentry.io/6516882",
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    beforeSend(event) {
      return event;
    },
    integrations: [new Sentry.Replay()],
  });
}

export function startBraze() {
  init(brazeKey, VERSION);
}

export function startAppsFlyer() {
  console.debug("Appsflyer is not implemented on web");
}

export const getConversionData = startAppsFlyer;

export function useAppReview() {
  console.debug("useAppReview NOT IMPLEMENTED YET ON WEB");

  return null;
}
