import Navigation from "./navigation";
import {
  appUiStore,
  getFreshToken,
  getQueryParams,
  isJwtExpired,
  mobileApi,
  useAppLang,
} from "./Lib";
import { QueryProvider } from "./Lib/queries/Provider";
import { storage } from "./Lib/storage";
import { Spinner } from "./components/Spinner";
import { requestPermission } from "./firebase/messaging";
import { useAppTheme } from "./hooks";
import { priceEngine } from "./Lib/PriceEngine";
import { ToastWithConfig } from "./components/ToastUI";
import { themeOverride } from "@/themes";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { SafeAreaProvider } from "react-native-safe-area-context";
import "./index.css";
import { observer } from "mobx-react-lite";
import { useWindowDimensions, View } from "react-native";
import { lazy, Suspense, useEffect, useState } from "react";
import { captureException } from "@sentry/react";
import { UIContext } from "@amana/ui";

const AppGatewayStack = createNativeStackNavigator();

function Gateway() {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const params = getQueryParams(window.location.href);
    if (params?.token) {
      storage.set("refresh_token", params.token);
    }
    const init = async () => {
      const token = isJwtExpired("PREVIOUS_SESSION_TOKEN");
      const refreshToken = isJwtExpired("refresh_token");
      if (token) {
        appUiStore.processAccesstoken({
          access_token: token,
          refresh_token: refreshToken || undefined,
        });
      }
      if (refreshToken) {
        console.warn("NO ACCESS TOKEN, BUT THERE'S A REFRESH");
        await getFreshToken(refreshToken);
      }
    };

    init().finally(() => {
      setReady(true);
      console.log("BootSplash has been hidden successfully");
    });
  }, []);

  if (!ready) return <Spinner />;

  return (
    <QueryProvider>
      <WithLanguage />
    </QueryProvider>
  );
}

const Protected = LazyHoc(lazy(() => import("./ProtectedApp")));
const Public = LazyHoc(lazy(() => import("./Features/PublicApp")));

function LazyHoc(Component: any) {
  return () => (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  );
}

const WithLanguage = observer(() => {
  const { token } = appUiStore;
  const [viewPort, setViewPort] = useState(
    window.innerWidth > 768 ? "desktop" : "mobile"
  );
  useEffect(() => {
    if (!token) return;
    requestPermission().then((fcmToken) => {
      if (fcmToken)
        mobileApi
          .post("/register-device", { firebase_token: fcmToken })
          .catch(captureException);
    });
  }, [token]);

  useEffect(() => {
    const handleResize = () => {
      const view = window.innerWidth > 768 ? "desktop" : "mobile";
      if (view === "mobile") {
        document.querySelector("#contain")?.classList.add("mobile");
      } else {
        document.querySelector("#contain")?.classList.remove("mobile");
      }
      setViewPort(view);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(
    function gatewayUseEffect() {
      if (token && priceEngine.isConnected() === false)
        priceEngine.setup(token);
    },
    [token]
  );

  return (
    <>
      <View style={{ flex: 1 }} key={viewPort}>
        <SyncWindowSize />
        <SafeAreaProvider>
          <Navigation>
            <AppGatewayStack.Navigator screenOptions={{ headerShown: false }}>
              {token ? (
                <AppGatewayStack.Screen name="RootApp" component={Protected} />
              ) : (
                <AppGatewayStack.Screen name="AuthApp" component={Public} />
              )}
            </AppGatewayStack.Navigator>
          </Navigation>
        </SafeAreaProvider>
      </View>
      <ToastWithConfig />
    </>
  );
});

const WithColorScheme = observer(() => {
  const [locale] = useAppLang();
  const [theme] = useAppTheme();
  return (
    <UIContext.Provider
      value={{ theme, locale: locale as "en", overrideTheme: themeOverride }}
    >
      <Gateway />
    </UIContext.Provider>
  );
});

function SyncWindowSize() {
  const { width, height } = useWindowDimensions();
  useEffect(() => {
    appUiStore.setAppDimensions({ width, height });
  }, [width, height]);
  return null;
}

export default WithColorScheme;
