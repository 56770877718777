/*
 * * * * * * * * * * * * * * * *
 * * START PRODUCTION BLOCK  * *
 * * * * * * * * * * * * * * * *
 */

export const isProduction: boolean = true;
export const TAPI_HTTP = "https://mobilebackend.amanalabs.net";
export const BACKOFFICE_HTTP = "https://mobilebackend.amanalabs.net/api/v1";
export const BACKOFFICE_HTTP_V3 = "https://mobilebackend.amanalabs.net/api/v3";
export const PRICE_WS = "wss://mobilebackend.amanalabs.net/market-data";
export const checkoutPublicKey = "pk_hrosmjfdjjqwudc3ivfm3ogcwyn";
export const leanToken = "1d18a5af-cdd7-49e0-9648-92562016130d";
export const brazeKey = "2366f47d-147d-41c7-ad0e-615e3265e9cb";

export const INVEST_API = "https://api.getamana.app";

/*
 * * * * * * * * * * * * * *
 * * START STAGING BLOCK * *
 * * * * * * * * * * * * * *
 */
// export const isProduction = true;
// export const TAPI_HTTP = 'https://test01.centroidsol.com';
// export const BACKOFFICE_HTTP = 'https://api-dev.amanacapital.com/api/v1';
// export const BACKOFFICE_HTTP_V3 = 'https://api-dev.amanacapital.com/api/v3';
// export const checkoutPublicKey = 'pk_sbox_kle3hfoffztvdjt6zj42tiszeae';
// export const leanToken = '679d511e-b269-4342-8b7c-e57222ff7814';
// export const PRICE_WS = 'wss://mobilebackend.amanalabs.net/market-data';
// export const brazeKey = '0e5c4596-11cf-41df-8be1-56e01c069f25';

/*
 * * * * * * * * * * * * * * *
 * * START LOCALHOST BLOCK * *
 * * * * * * * * * * * * * * *
 */
// export const isProduction = false;
// export const TAPI_HTTP = 'http://localhost:8999';
// export const BACKOFFICE_HTTP = 'http://localhost:3001/api/v1';
// export const BACKOFFICE_HTTP_V3 = 'http://localhost:3001/api/v3';
// export const PRICE_WS = 'wss://localhost:6789';
// export const C2C_HTTP = 'http://localhost:8999';

// Common exports across all environments
export { version as VERSION, buildNumber as DIST } from "../../package.json";
export const GOOGLE_WEB_CLIENT_ID =
  "182007695339-jc1fjnh84pu9m5sllp8u2e9f0d1766gr.apps.googleusercontent.com";
// export const HISTORY_API = 'http://localhost:9999/history-api';
export const HISTORY_API = "https://mobilebackend.amanalabs.net/history-api";
export const ASSETINFO_API = "https://airflow.amanacapital.com";
export const CMS_HTTP = "https://crm.centroidsol.com/api";

export const UAEPASS_ENV = "staging";
export const UAEPASS_CLIENT_ID = "amana_mob_stage";
export const UAEPASS_WEB_REDIRECT_URI =
  "https://mobilebackend.amanalabs.net/auth/callback";
export const UAEPASS_BASE_URL = "https://stg-id.uaepass.ae";
export const UAEPASS_SCOPE =
  "urn:uae:digitalid:profile:general urn:uae:digitalid:digitalvault:datasharerequest";
export const MAXIMUMDEPOSIT = 6000;
